import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/standard-page.scss';
import '../../assets/styles/pages/accident-management.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ICIB from '../../images/crash-management/testimonials/ICIB.jpg'
import HPLogo from '../../images/crash-management/testimonials/HP-logo.png'
import Energiser from '../../images/crash-management/testimonials/energiser.jpg'
import WTW from '../../images/crash-management/testimonials/wtw_logo_vrt_rgb-80.png'
import TestimonialsHeader from '../../images/crash-management/testimonials/testimonials.jpg'
import AccidentSubNav from "../../components/accident-sub-nav";

const AccidentManagementTestimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Testimonials" />
      <AccidentSubNav />
      <div className="hero-image">
        <div className="container">
          <img src={TestimonialsHeader} />
        </div>
      </div>
      <div className="main-wrapper">
        <section className="py-7">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Testimonials</h1>

                <div className="entry-content" itemProp="mainContentOfPage">
                  <h2>Business fleets prefer Crash Management – read what our clients have say</h2>
                  <p>Crash Management is New Zealand’s No.1 preferred accident management &amp; collision repair
                    service. Our reputation is a direct result of our uncompromised focus on customer service and
                    satisfaction – but don’t just take our word for it, see what our customers are saying about us.</p>
                  <p>We serve a range of business partners including the insurance broker sector, car leasing firms and car
                    manufacturers. We also serve private motorists in addition to a wide range of government, corporate, and SME fleets. We are pleased to present a small sample of
                    client and business partner testimonials for your reference, or you may wish to refer to our media coverage.
                  </p>
                  <h4>Client Testimonials</h4>
                  <blockquote className="accident-quote"><p><img src={HPLogo} />
                    I knew about Crash Management through the NZICA LifeStyles Benefits programme. Crash Management took
                    over the admin of getting my panel beating organised and regularly updated me with the relevant
                    details. My damaged car was picked up from my workplace and replaced with a late-model courtesy car.
                    It was then returned a few days later in perfect condition with panel beating &amp; valet completed.
                    I would recommend Crash Management to all who have suffered a similar misfortune as they make what
                    could easily be a time consuming, frustrating experience a very easy &amp; satisfying one.<br />
                      <strong>– Andrea Clark,</strong> Accountant, Hewlett Packard</p></blockquote>
                  <blockquote className="accident-quote"><p><img src={Energiser} />
                    We have used Crash Management a number of times. Their professionalism has assisted us through the
                    smash repair process and their ability to manage the repair from start to finish means that
                    management’s involvement is minimal. The experience has been so good in the Auckland area that I
                    have distributed Crash Management VIP Customer cards to our drivers throughout the country. Using
                    Crash Management to manage our car accidents and general panel beating frees up management time for
                    more productive activities, and we are more than satisfied with the outcome. I would recommend Crash
                    Management to any organisation.<br />
                      <strong>– Peter Treacy,</strong> Commerce &amp; Operations Manager, Energizer NZ Ltd</p>
                  </blockquote>
                  <h4>Business Partners Testimonials</h4>
                  <blockquote className="accident-quote"><p><img src={WTW} />We have been very
                    impressed with the responsiveness and professional service the Crash Management team provide. They
                    have demonstrated on a number of occasions their ability to find solutions to problems that we
                    believe only a dedicated Motor Claims handling team can provide. We have no hesitation in
                    recommending their services as we have found they provide considerable value to our clients.<br />
                      <strong>– Tim Girvan</strong>, Account Manager, WillisTowersWatson</p></blockquote>
                  <blockquote className="accident-quote"><p><img src={ICIB} />Time
                    after time I am impressed by the outstanding service provided by Crash management, I really commend
                    their prompt service. It’s a very unique service and creates a point of difference that makes what
                    can be a stressful time for our client into a seamless end to end process. Their calm and logical
                    approach gives us confidence that we can completely trust their team to represent our company
                    professionally with our clients.&nbsp; I always feel Crash management demonstrate a genuine effort
                    to provide a great service which is encouraging for developing a long-term relationship.<br />
                      –&nbsp;<strong>Grant Milne,&nbsp;</strong>Chief Executive Officer, ICIB Insurance Brokers.</p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AccidentManagementTestimonials;